import React, { useEffect, useState } from "react";
import { CircularProgress, TextField, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, TableHead, IconButton, Tooltip, Select, MenuItem, FormControl, TablePagination, Checkbox, FormControlLabel } from '@mui/material'
import { useDataProvider, useNotify, Button } from "react-admin";
import { EditRoad, GetApp, Map } from "@mui/icons-material";
import { TripRouteDownload } from "./TripRouteDownloadButton";
import { useNavigate } from "react-router-dom";

const MAX_TRACE_PTS = 50000

const RenderTripRoute = ({ routes, username, clientKey }) => {
    const [loadingRouteId, setLoadingRouteId] = useState(null);
    const [loadingSnapshotId, setLoadingSnapshotId] = useState(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const navigate = useNavigate();
    const [gotGeometry, setGotGeometry] = useState(null);
    const [gotHereRes, setGotHereRes] = useState(null);
    const [geometry, setGeometry] = useState([]);
    const [badChunks, setBadChunks] = useState(null);
    const [snapShotData, setSnapShotData] = useState(null);
    const [polylines, setPolylines] = useState([]);
    const hereURL = `https://router.hereapi.com/v8/import?apikey=${process.env.REACT_APP_HERE_MAP_API_KEY}&return=polyline,summary,actions,instructions&transportMode=truck`;

    const hereImport = async (tracePoints) => {
        notify('Retrieving HERE route import response...', { type: 'info' });
        try {
            const response = await fetch(hereURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ trace: tracePoints }),
            });

            if (!response.ok) {
                throw new Error('Failed to import route to HERE API');
            }
            setGotHereRes(true);
            const result = await response.json();
            result.routes.forEach(route => {
                route.sections.forEach(section => {
                    polylines.push(section.polyline);
                });
            });
            notify('Received HERE import response.', { type: 'success' });
        } catch (error) {
            setGotHereRes(false);
            notify(`Error importing route: ${error.message}`, { type: 'error' });
        }
    };

    const fetchGeometry = async (routeId) => {
        setGotGeometry(null);
        setGotHereRes(null);
        setLoadingRouteId(routeId);
        notify(`Retrieving route ${routeId} geometry...`, { type: 'info' });
        try {
            const { data } = await dataProvider.getOne('routes', {
                filter: { id: routeId },
            });
            const tracePoints = [];
            data.geometry.forEach(point => {
                tracePoints.push({
                    lat: point.latitude,
                    lng: point.longitude,
                });
            });
            setGeometry(tracePoints);
            setGotGeometry(true);
            if (tracePoints.length > MAX_TRACE_PTS) {
                notify(`Too many trace points (${tracePoints.length}). Getting bad chunks only...`, { type: 'error' });
                setBadChunks(data.bad_geometry_chunks);
            } else {
                await hereImport(tracePoints);
            }
        } catch (err) {
            notify(`Error retrieving geometry: ${err.message}`, { type: 'error' });
            setGotGeometry(false);
            return [];
        } finally {
            setLoadingRouteId(null);
            return true
        }
    };

    const handleDebugClick = () => {
        geometry.length > 0
            ? navigate('/here_map', { state: { geometry, polylines, badChunks, snapShotData } })
            : notify('Unable to debug route: Geometry length is 0', { type: 'error' })
    };

    const handleTPSClick = async (routeId, loadId, uuid) => {
        setLoadingSnapshotId(routeId);
        notify(`Retrieving snapshot data...`, { type: 'info' });
        try {
            const { data } = await dataProvider.getOne('trip_plans', {
                filter: { clientKey: clientKey, username: username, loadId: loadId, uuid: uuid }
            });
            setSnapShotData(data);
            setLoadingSnapshotId(null);
            navigate('/here_map', { state: { geometry, polylines, badChunks, snapShotData: data } })
        } catch (error) {
            notify(`Error retrieving snapshot: ${error}`, { type: 'error' })
        }
    }

    return (
        <React.Fragment>
            {routes.map((trip_route) => (
                <TableBody>
                    <TableRow key={trip_route.id}>
                        <TableCell>
                            {trip_route.id}
                        </TableCell>
                        <TableCell>{trip_route.trip_plan_uuid}</TableCell>
                        <TableCell>{trip_route.routing_engine || 'HERE Standard'}</TableCell>
                        <TableCell>{trip_route.load_reference}</TableCell>
                        <TableCell style={{ textAlign: 'center', backgroundColor: trip_route.import_status === 'failed' ? '#ffa2a1' : '' }}>{trip_route.import_status || "-"}</TableCell>
                        <TableCell>{trip_route.created_at}</TableCell>
                        <TableCell>
                            <FormControl size="small">
                                <Select id="download-select" displayEmpty renderValue={() => <GetApp fontSize="small" />}>
                                    <MenuItem disabled value="">Select Download:</MenuItem>
                                    <MenuItem><TripRouteDownload label={"GPX"} trip_plan_uuid={trip_route.trip_plan_uuid} loadId={trip_route.load_reference} fileType={'gpx'} username={username} clientKey={clientKey} /></MenuItem>
                                    <MenuItem><TripRouteDownload label={"Load"} trip_plan_uuid={trip_route.trip_plan_uuid} loadId={trip_route.load_reference} fileType={'loadData'} username={username} clientKey={clientKey} /></MenuItem>
                                    <MenuItem><TripRouteDownload label={"Snapshot"} trip_plan_uuid={trip_route.trip_plan_uuid} loadId={trip_route.load_reference} fileType={'snapshot'} username={username} clientKey={clientKey} /></MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <div style={{ display: 'flex' }}>
                                <Tooltip title="Pluggable Debugger">
                                    {loadingRouteId === trip_route.id ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <React.Fragment>
                                            {trip_route.import_status === 'failed' && !gotGeometry && <IconButton onClick={() => fetchGeometry(trip_route.id)}><EditRoad /></IconButton>}
                                            {gotGeometry && trip_route.import_status === 'failed' && <Button label="Debug Map" onClick={handleDebugClick} />}
                                        </React.Fragment>
                                    )}
                                </Tooltip>
                            </div>
                        </TableCell>

                        <TableCell>
                            <div style={{ display: 'flex' }}>
                                {loadingSnapshotId === trip_route.id ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <IconButton onClick={() => { handleTPSClick(trip_route.id, trip_route.load_reference, trip_route.trip_plan_uuid) }}><Map /></IconButton>
                                )
                                }
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            ))}
        </React.Fragment>
    );
};

export const TripRoutesForm = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showPagination, setShowPagination] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [username, setUsername] = useState(localStorage.getItem("TR_username") || "");
    const [clientKey, setClientKey] = useState(localStorage.getItem("TR_client_key") || "");
    const [reasonCodes, setReasonCodes] = useState({});
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [routes, setRoutes] = useState([]);
    const [requestSent, setRequestSent] = useState(false)
    const [expandedRow, setExpandedRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const dataProvider = useDataProvider();

    useEffect(() => {
        const now = new Date();
        const twoWeeksAgo = new Date(now);
        twoWeeksAgo.setDate(now.getDate() - 14);
        setStart(twoWeeksAgo.toISOString());
        setEnd(now.toISOString());
    }, []);

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClear = () => {
        setRequestSent(false);
        localStorage.removeItem("TR_client_key")
        localStorage.removeItem("TR_username")
        setClientKey("");
        setUsername("");
        setRoutes([]);
        setShowCheckbox(false);
    }
    const submitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);

        // Field validation
        const fields = { clientKey, username, start, end };
        for (const [key, value] of Object.entries(fields)) {
            if (!value) {
                notify(`${key} is required`, { type: 'error' });
                setLoading(false);
                return;
            }
        }

        // Proceed to fetch data if all fields are valid
        try {
            const { data } = await dataProvider.getList('trip_routes', {
                filter: { clientKey, username, start, end },
            });
            setRequestSent(true);
            setRoutes(data);
            setShowCheckbox(true);
        } catch (error) {
            notify(`Error fetching trip routes: ${error.message}`, { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const RenderTripRoutes = () => {
        const displayedRoutes = showPagination
            ? routes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : routes;
        return (
            <TableContainer component={Paper} elevation={3} style={{ margin: "10px", maxHeight: "80vh" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Route ID</b></TableCell>
                            <TableCell><b>UUID</b></TableCell>
                            <TableCell><b>Routing Engine</b></TableCell>
                            <TableCell><b>Load ID</b></TableCell>
                            <TableCell><b>Import Status</b></TableCell>
                            <TableCell><b>Created At</b></TableCell>
                            <TableCell style={{ textAlign: 'center' }}><b>Downloads</b></TableCell>
                            <TableCell style={{ textAlign: 'center' }}><b>PR Debug</b></TableCell>
                            <TableCell style={{ textAlign: 'center' }}><b>NTPS</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <RenderTripRoute routes={displayedRoutes} username={username} clientKey={clientKey} />
                </Table>
                {showPagination && (
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={routes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </TableContainer>
        );
    };

    const RenderResults = () => {
        if (loading) return <CircularProgress />;
        return requestSent ? (routes.length > 0 ? <RenderTripRoutes /> : <Typography>No Trip Routes Found</Typography>) : (<></>)
    }

    return (
        <div style={{ textAlign: 'center', marginRight: '3%' }}>
            <h2>Trip Routes</h2>
            <form onSubmit={submitHandler}>
                <div style={{ margin: "8px" }}>
                    <TextField
                        variant="outlined"
                        label="Client Key"
                        value={clientKey}
                        size="small"
                        onChange={(e) => { setClientKey(e.target.value); localStorage.setItem("TR_client_key", e.target.value) }}
                        style={{ width: '30%' }}
                    />
                </div>
                <div style={{ margin: "8px" }}>
                    <TextField
                        variant="outlined"
                        label="Username"
                        value={username}
                        size="small"
                        onChange={(e) => { setUsername(e.target.value); localStorage.setItem("TR_username", e.target.value) }}
                        style={{ width: '30%' }}
                    />
                </div>
                <div style={{ margin: "8px" }}>
                    <TextField
                        variant="outlined"
                        label="Start Date (UTC)"
                        type="datetime-local"
                        value={start.slice(0, -1)}
                        onChange={(e) => setStart(e.target.value + "Z")}
                        style={{ width: '30%' }}
                    />
                </div>
                <div style={{ margin: "8px" }}>
                    <TextField
                        variant="outlined"
                        label="End Date (UTC)"
                        type="datetime-local"
                        value={end.slice(0, -1)}
                        onChange={(e) => setEnd(e.target.value + "Z")}
                        style={{ width: '30%' }}
                    />
                </div>
                <div style={{ margin: "8px" }}>
                    <Button style={{ marginRight: '5px' }} type='submit' variant="contained" color="primary" label="Request" />
                    <Button style={{ marginLeft: '5px', marginRight: '5px', backgroundColor: '#c8c8c8' }} onClick={handleClear} variant="outlined" label="Clear" />
                </div>
                {showCheckbox && <FormControlLabel
                    control={<Checkbox checked={showPagination} onChange={(e) => setShowPagination(e.target.checked)} />}
                    label="Paginate Results?"
                    style={{ marginLeft: '5px' }}
                />}
            </form>
            {RenderResults()}
        </div>
    )
}